import { apiCall } from './api.service';

export const getPayments = async (token, organisationId) => {
    const method = 'get';
    const path = `api/v1/organisations/${organisationId}/payments`;

    const response = await apiCall(method, {}, path, token);
    return response;
};

export const getPaymentDetails = async (token, paymentId, organisationId) => {
    const method = 'get';
    const path = `api/v1/organisations/${organisationId}/payments/${paymentId}`;

    const response = await apiCall(method, {}, path, token);
    return response;
};

export const authorizePayment = async (token, paymentId, otp, organisationId, isRejected = false) => {
    const method = 'post';
    const body = { otp, organisationId, isRejected };
    const path = `api/v1/organisations/${organisationId}/payments/${paymentId}/authorise`;

    const response = await apiCall(method, body, path, token);
    return response;
};

export const getPaymentLogs = async (token, paymentId) => {
    const method = 'get';
    const path = `api/v1/logs/payments/${paymentId}`;

    const response = await apiCall(method, {}, path, token);
    return response;
};