import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/authContext';
import { getPayments } from '../services/payment.service';
import PaymentsTable from "../components/payments/PaymentsTable";

const PaymentsPage = () => {
    const [payments, setPayments] = useState([]);
    const [pendingPayments, setPendingPayments] = useState([]);
    const [approvedPayments, setApprovedPayments] = useState([]);
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();

    useEffect(() => {
    
        const fetchPayments = async () => {
            setLoading(true);
            setPayments([]);
            setPendingPayments([]);
            setApprovedPayments([]);
            const response = await getPayments(user.token, user.organisationId);
            if (response && response.data.success) {
                const paymentData = response.data.data;

                setPayments(paymentData.reverse());
                setPendingPayments(paymentData.filter(payment => payment.status === 'Pending'));
                setApprovedPayments(paymentData.filter(payment => payment.status === 'Approved' || payment.status === 'Rejected'));
            }
            setLoading(false);
        };

        fetchPayments();
        
    }, [user]);

    return (
        <div className="App">
            <div className=''>
                <div className='flex flex-col gap-6 w-full max-w-9xl mx-auto'>
                    <PaymentsTable loading={loading} payments={pendingPayments} title='Pending Payments' />
                    <PaymentsTable loading={loading} payments={approvedPayments} title='Approved / Rejected Payments' />
                </div>
            </div>
        </div>
    );
};

export default PaymentsPage;