import React, { useEffect, useState } from 'react';
import '../../index.css';
import { useAuth } from "../../context/authContext";
import { Card, CardContent, CardHeader } from "../ui/card";
import {useParams} from 'react-router-dom';
import { getPaymentDetails } from '../../services/payment.service';
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../components/ui/table";

const currencyFormat = (value) => {
    return new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
};

const convertAccountDetailsType = (type) => {
    switch (type) {
        case 'IBAN':
            return 'IBAN';
        case 'BIC':
            return 'BIC';
        case 'ACCOUNT_NO':
            return 'Account Number';
        case 'ACCOUNT_NUMBER':
                return 'Account Number';
        case 'SORT_CODE':
            return 'Sort Code';
        default:
            return '';
    }
};

const PaymentDetails = () => {
    const { authenticated } = useAuth();
    const { paymentId } = useParams();
    const { user } = useAuth();
    const [payment, setPayment] = useState(null);

    useEffect(() => {
        const fetchPaymentDetails = async () => {
            const response = await getPaymentDetails(user.token, paymentId, user.organisationId);
            if (response && response.status === 200) {
                setPayment(response.data.data);
            }
        };

        fetchPaymentDetails();
    }, []);

    const renderPaymentData = (paymentData) => {
        if (paymentData.payments) { // Bulk payment
            return (
                <>
                    <Table>
                        <TableHeader>
                            <TableRow className='hover:bg-white'>
                                <TableHead>Payee</TableHead>
                                {paymentData.payments[0].payee.accountIdentifications[0] ? <TableHead>{convertAccountDetailsType(paymentData.payments[0].payee.accountIdentifications[0].type)}</TableHead> : null}
                                {paymentData.payments[0].payee.accountIdentifications[1] ? <TableHead>{convertAccountDetailsType(paymentData.payments[0].payee.accountIdentifications[1].type)}</TableHead> : null}
                                <TableHead>Reference</TableHead>
                                <TableHead className="text-right">Amount</TableHead>
                                <TableHead>Currency</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {paymentData.payments.map((payment) => (
                                <>
                                    <TableRow key={payment.paymentId}>
                                        <TableCell>{payment.payee.name}</TableCell>
                                        {payment.payee.accountIdentifications[0] ? <TableCell> {payment.payee.accountIdentifications[0].identification} </TableCell> : null}
                                        {payment.payee.accountIdentifications[1] ? <TableCell> {payment.payee.accountIdentifications[1].identification} </TableCell> : null}
                                        <TableCell>{payment.reference}</TableCell>
                                        <TableCell className="text-right">{currencyFormat(payment.amount.amount)}</TableCell>
                                        <TableCell>{payment.amount.currency}</TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </>
            );
        } 
        // else { // Single payment
        //     return (
        //         <>
        //             <Table>
        //                 <TableHeader>
        //                     <TableRow className='hover:bg-white'>
        //                         <TableHead>Payee</TableHead>
        //                         <TableHead>Reference</TableHead>
        //                         <TableHead className="text-right">Amount</TableHead>
        //                         <TableHead>Currency</TableHead>
        //                     </TableRow>
        //                 </TableHeader>
        //                 <TableBody>
        //                     <TableRow key={payment.paymentId}>
        //                         <TableCell>{payment.paymentData.payee}</TableCell>
        //                         <TableCell>{payment.paymentData.reference}</TableCell>
        //                         <TableCell className="text-right">{currencyFormat(paymentData.amount.amount)}</TableCell>
        //                         <TableCell>{payment.paymentData.amount.currency}</TableCell>
        //                     </TableRow>
        //                 </TableBody>
        //             </Table>
        //         </>
        //     );
        // }
    };

    return (
        <div className='min-w-max'>
            <Card>
                <CardHeader>
                    <h3>Payment Details</h3>
                </CardHeader>
                <CardContent>
                    { authenticated && payment ?
                        <div>
                            {renderPaymentData(payment)}
                        </div>
                        :
                        <div className="blur select-none">
                            <p>Details cannot be displayed.</p>
                        </div>
                    }
                </CardContent>
            </Card>
        </div>
    );
};

export default PaymentDetails;