import { Card, CardContent, CardHeader } from "../ui/card";
import { Button } from "../ui/button";
import React, {useState, useEffect} from "react";
import { useAuth } from '../../context/authContext';
import { getUserDetails } from '../../services/user.service';
import { authorizePayment } from '../../services/payment.service';
import { useParams } from 'react-router-dom';
import { useToast } from "../../components/ui/use-toast";
import { AlertCircle } from "lucide-react";
import CircularProgress from '@mui/material/CircularProgress';
import checkIcon from '../../images/check.svg';
import failIcon from '../../images/fail.svg';


import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "../../components/ui/alert";

import {
    InputOTP,
    InputOTPGroup,
    InputOTPSlot,
  } from "../../components/ui/input-otp"

export default function PaymentAuth({ paymentDetails, updatePage, setUpdatePage }) {

    const { toast } = useToast()
    const [isChecked, setIsChecked] = useState(false);
    const [otp, setOtp] = useState('');
    const { user, setUser } = useAuth();
    const [isEditable, setIsEditable] = useState(true);
    const { paymentId } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchUserDetails = async () => {
            const response = await getUserDetails(user.token, user.organisationId, user.id);
            if (response && response.status === 200) {
                const { twoFactorEnabled } = response.data.data;
                setUser({ ...user, twoFactorEnabled });
                setIsEditable(twoFactorEnabled);
            }
        };

        fetchUserDetails();
    }, []);

    const handleAuthorizePayment = async (isRejected = false) => {
        setLoading(true);
        const response = await authorizePayment(user.token, paymentId, otp, user.organisationId, isRejected);
        setLoading(false);
        if (response && response.status === 200) {
            toast({
                title: isRejected ? "Rejected" : "Success",
                description: isRejected ? "Payment Rejected Successfully" : "Payment Authorised Successfully",
                variant: isRejected ? "destructive" : "success",
            });
            setOtp('');
            setUpdatePage((prev) => !prev);
        } else {
            const errorMessage = response.data.error ? response.data.error.message : isRejected ? "Payment Rejection Failed" : "Payment Authorisation Failed";
            toast({
                title: "Error",
                description: errorMessage,
                variant: "destructive",
            });
            setOtp('');
        }
    };

    return (
        <div className='w-full'>
            {isEditable ? (
                <Card className='h-full'>
                    <CardHeader>
                        <h3>Payment Auth</h3>
                    </CardHeader>
                    <CardContent>
                        {loading ? (
                            <div className='flex justify-center items-center'>
                                <CircularProgress />
                            </div>
                        ) :
                        <>
                        { 
                        // (paymentDetails.status === 'Approved' || paymentDetails.status === 'Rejected' || paymentDetails.authorisedBy.includes(user.id)) ?
                        (paymentDetails.status === 'Approved' || paymentDetails.status === 'Rejected') ?
                            <div className="flex flex-row justify-start items-center">
                                <img src={paymentDetails.status === 'Rejected' ? failIcon : checkIcon} alt={paymentDetails.status === 'Rejected' ? "fail" : "check"} className="w-8 h-8 mr-4" />
                                <p className="mb-0">
                                    {paymentDetails.status === 'Rejected' ? 
                                        "This payment has been rejected and cannot be reauthorized." :
                                        "You have already approved this payment and/or it has been fully approved by users in your organisation."
                                    }
                                </p>
                            </div>
                            :
                            <>
                                <div className=''>
                                    <label className='text-gray-500 text-sm'>
                                        <input
                                            className="mr-2 leading-tight"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={() => setIsChecked(!isChecked)}
                                        />
                                        I authorise this payment on behalf of <b>{user.organisationName}</b>
                                    </label>
                                </div>
                                <div className='flex flex-col items-center mt-10'>
                                    <p className="mb-4">Please enter the code from your authenticator app</p>
                                    <InputOTP 
                                        maxLength={6}
                                        value={otp}
                                        onChange={(value) => setOtp(value)}
                                        disabled={!isChecked}  
                                    >
                                        <InputOTPGroup>
                                            <InputOTPSlot index={0} />
                                        </InputOTPGroup>
                                        <InputOTPGroup>
                                            <InputOTPSlot index={1} />
                                        </InputOTPGroup>
                                        <InputOTPGroup>
                                            <InputOTPSlot index={2} />
                                        </InputOTPGroup>
                                        <InputOTPGroup>
                                            <InputOTPSlot index={3} />
                                        </InputOTPGroup>
                                        <InputOTPGroup>
                                            <InputOTPSlot index={4} />
                                        </InputOTPGroup>
                                        <InputOTPGroup>
                                            <InputOTPSlot index={5} />
                                        </InputOTPGroup>
                                    </InputOTP>
                                    <div className="flex space-x-4 mt-12">
                                        <Button className="yvr-button" onClick={() => handleAuthorizePayment(false)} disabled={!isChecked}>
                                            Authorise Payment
                                        </Button>
                                        <Button className="yvr-button-red text-white" onClick={() => handleAuthorizePayment(true)} disabled={!isChecked}>
                                            Reject
                                        </Button>
                                    </div>
                                </div>
                            </>
                            }
                        </>
                        }
                    </CardContent>
                </Card>
            ) : (
                <div className='flex flex-col justify-center items-center'>
                    <div className='max-w-2xl'>
                        <Alert variant="destructive">
                            <AlertCircle className="h-4 w-4" />
                            <AlertTitle>Authenticator app not enabled</AlertTitle>
                            <AlertDescription>
                                You have not enabled your Authenticator app. Go to the Settings Page to connect your device.
                            </AlertDescription>
                        </Alert>
                    </div>
                </div>
            )}
        </div>
    );
}